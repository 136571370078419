import {
  clone,
} from 'lodash';

import ConveYourEndpoint      from '@/api/ConveYourEndpoint.js';
import DeviceContactDirectory from '@/api/DeviceContactDirectory.ts';

import {
  asNullablePhoneNumber,
  isEqualTo,
} from '@/lib/stringSubset/PhoneNumber.ts';

function getContactsFromLocalStorage() {
  return JSON.parse(localStorage.getItem('contacts')) || [];
}

function prepareReferrals(arr) {
  return arr.map(({ first_name, last_name, mobile }) => ({ first_name, last_name, mobile }));
}

export default {
  async fetchContacts({ commit }) {
    commit('setContactsLoading', true);

    const newlyReadContacts = await DeviceContactDirectory.tryToReadAllContacts() ?? [];

    const contacts = newlyReadContacts.map(($0) => ({
      messageSent: false, // TODO: Use state management to avoid schema pollution
      selected: false, // TODO: Use state management to avoid schema pollution
      saved: false, // TODO: Use state management to avoid schema pollution
      introduced: false, // TODO: Use state management to avoid schema pollution
      ...$0,
    }));

    const contactsFromLocalStorage = getContactsFromLocalStorage();

    contactsFromLocalStorage.forEach((contactFromLocalStorage) => {
      const contactIndex = contacts.findIndex(($0) => $0.id === contactFromLocalStorage.id);

      if (contactIndex === -1) {
        contacts.push(contactFromLocalStorage);
      } else {
        contacts.splice(contactIndex, 1, contactFromLocalStorage);
      }
    });

    try {
      const fetchedReferrals = await ConveYourEndpoint.getReferrals();

      fetchedReferrals?.forEach((eachReferral) => {
        const referralMobileNumber = asNullablePhoneNumber(eachReferral.mobile);

        const contactIndex = contacts.findIndex((eachContact) => {
          const eachMobileNumber = asNullablePhoneNumber(eachContact.mobile);
          return isEqualTo(eachMobileNumber, referralMobileNumber);
        });

        if (contactIndex === -1) return;

        const updatedContact = {
          ...clone(contacts[contactIndex]),
          serverId: eachReferral.id,
        };

        contacts.splice(contactIndex, 1, updatedContact);
      });
    } catch (error) {
      console.error('fetchContacts', error);
    }

    commit('setContacts', contacts);
    commit('setContactsLoading', false);
  },

  async getReferralsCount() {
    try {
      const data = await ConveYourEndpoint.getReferralsCount();
      return data.count || 0;
    } catch (error) {
      console.error('getReferralsCount', error);
      return 0;
    }
  },

  addContact({ commit }, contact) {
    const contacts = getContactsFromLocalStorage();
    contacts.push(contact);
    commit('setContactsLocally', contacts);
  },

  async shareContacts({ commit }, contacts) {
    commit('setContactsLoading', true);

    try {
      return await ConveYourEndpoint.submitReferrals(prepareReferrals(contacts));
    } finally {
      commit('setContactsLoading', false);
    }
  },

  async loadIntroductionText({ getters }, { contactId = '', lang = 'en' }) {
    try {
      const { message } = await ConveYourEndpoint.getIntroductionText({ contactId, lang });
      return message;
    } catch (error) {
      const { first_name: referral } = getters.singleContact(contactId);
      const { first_name: rep      } = getters.rep ?? {};
      return `Hi ${referral}! Introducing you to ${rep}.`;
    }
  },
};
