import Axios from 'axios';

const usingProxy = process.env.VUE_APP_USE_PROXY;
const apiURL     = process.env.VUE_APP_API_URL || '';

const baseURL = (usingProxy ? '' : apiURL) + '/plugin/vectorapps';

const axios = Axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem('user-token')}`;
      },
    },
  },
});

const { get, post, put } = axios;

const contacts     = 'contacts';
const schedules    = 'schedules';
const introduction = 'introduction';
const rep          = 'rep';
const surveys      = 'surveys';

export default {
  /* eslint-disable comma-spacing, key-spacing, space-in-parens */
  getTokenAndProfile            : (               data) => tryToExtractPayloadFrom(post(`/${contacts}/identity`                 , data)),
  getRepProfile                 : ({ contactId }      ) => tryToExtractPayloadFrom( get(`/${contacts}/${contactId}/rep`               )),
  getIntroductionText           : ({ contactId, lang }) => tryToExtractPayloadFrom( get(`/${contacts}/${contactId}/intro?lang=${lang}`)),
  tryToCreateProspect           : (               data) => tryToExtractPayloadFrom(post(`/${contacts}/prospects`                , data)),
  getReferrals                  : (                   ) => tryToExtractPayloadFrom( get(`/${contacts}/referred`                       )),
  getReferralsCount             : (                   ) => tryToExtractPayloadFrom( get(`/${contacts}/referred?count=1`               )),
  submitReferrals               : (               data) => tryToExtractPayloadFrom(post(`/${contacts}/referrals`                , data)),
  getIntroduction               : ({ pid }            ) => tryToExtractPayloadFrom( get(`/${introduction}/${pid}`                     )),
  getIntroductionBookingSnippets: ({ pid }            ) => tryToExtractPayloadFrom( get(`/${introduction}/${pid}/snippets`            )),
  updateIntroductionContact     : ({ contactId }, data) =>                        ( put(`/${introduction}/${contactId}`         , data)),
  getRep                        : ({ repNumber }      ) =>                        ( get(`/${rep}/${repNumber}`                        )),
  tryToFetchSurveyItems         : (                   ) => tryToExtractPayloadFrom( get(`/${surveys}`                                 )),
  tryToSubmitSurveyAnswers      : (               data) =>                        (post(`/${surveys}`                           , data)),
  tryToCreateAppointmentUsing   : (               data) => tryToExtractPayloadFrom(post(`/${schedules}`                         , data)),
  /* eslint-enable comma-spacing, key-spacing, space-in-parens */
};

async function tryToExtractPayloadFrom(triedAPICall) {
  const response = await triedAPICall;
  return response.data.data;
}
